import { FormHelperText } from '@mui/material';
import { forwardRef, memo } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	container: {
		color: theme.palette.error.dark,
		backgroundColor: theme.palette.error.light,
		padding: 8,
		borderRadius: 8,
		marginTop: 8,
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			width: '0',
			height: '0',
			top: -8,
			left: '4px',
			border: '.75rem solid transparent',
			borderTop: 'none',
			borderBottomColor: theme.palette.error.light
		}
	}
}));

const FormErrorHelperText = forwardRef((props: any, ref) => {
	const { helperText } = props || {};
	const { classes } = useStyles();

	return (
		<>
			<FormHelperText className={classes.container}>{helperText}</FormHelperText>
		</>
	);
});

export default memo(FormErrorHelperText);
