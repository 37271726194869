import bdtLogo from '@assets/images/bdtLogo.png';
import transferSolutionImg from '@assets/images/transferSolution.png';
import BeftnInfoContent from '@components/BeftnInfoContent';
import CustomGrid from '@components/shared/CustomGrid';
import { PATHS_BY_ENV } from '@constant/paths';
import useScreenViews from '@effects/useScreenViews';
import { env } from '@helpers/EnvHelper';
import { Link, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	btn: {
		borderRadius: '8px',
		color: 'white',
		marginTop: 16,
		padding: '10px 24px',
		'&.MuiButton-root': {
			color: 'white',
			background: 'radial-gradient(100% 730.99% at 0% 24.86%, #4568DC 0.49%, #B06AB3 92.48%)'
		}
	},
	textColor: {
		color: theme.surfaces.contrastText
	},
	solComp: {
		background: theme.palette.background.default,
		margin: '3rem 0 0'
	},
	btnHeader: {
		color: 'black',
		padding: '10px 12px',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	wrapGrid: {
		margin: '16px 0',
		[theme.breakpoints.down('md')]: {
			margin: '5rem 0 2rem'
		}
	},
	link: {
		textDecoration: 'none',
		color: 'black',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

export const withInfoSection = (WrappedFormComponent) => (props) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const { belowTabletView, belowMobileView } = useScreenViews();

	return (
		<>
			<CustomGrid>
				<Grid container columnGap={3} alignItems="center" margin="1rem 0" className={classes.wrapGrid}>
					<Grid item>
						<Typography variant="h4">{t('TOOLS')}</Typography>
					</Grid>
					<Grid item>
						<Link href="/" className={classes.link} fontWeight="bold">
							<Typography variant="body1" fontWeight="bold">
								{t('BDT_ROUTING_NUM')}
							</Typography>
						</Link>
					</Grid>
					<Grid item>
						<Link href={PATHS_BY_ENV[env].IFSCLink} target="_blank" className={classes.link}>
							<Typography variant="body1">{t('IFSC')}</Typography>
						</Link>
					</Grid>
				</Grid>
				<Grid container direction="column" alignItems="center" rowGap={1}>
					<Grid item>
						<Stack direction="row" alignItems="center" columnGap={1}>
							<img src={bdtLogo} alt="bdt logo" width="24px" height="24px" />
							<Typography variant="h3">{t('BDT')}</Typography>
							<Typography variant="h4" className={classes.textColor}>
								{t('BANGLADESHI_TAKA')}
							</Typography>
						</Stack>
					</Grid>
					<Grid item>
						<Typography marginTop={1} variant="h2">
							{t('ROUTING_NUMBER')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h5" textAlign="center">
							<Trans i18nKey="ROUTING_NUMBER_DESC" components={[<br />]} />
						</Typography>
					</Grid>
				</Grid>

				<Grid container margin="4rem 0 0">
					<WrappedFormComponent />
				</Grid>

				<Grid container>
					<BeftnInfoContent />
				</Grid>
			</CustomGrid>

			<Grid container className={classes.solComp}>
				<CustomGrid>
					<Grid container margin="3rem 0" textAlign={belowTabletView ? 'left' : 'right'} alignItems="center">
						<Grid item md={7} xs={12}>
							<Typography variant={belowMobileView ? 'h3' : 'h2'}>{t('TRANSFER_SOLUTION')}</Typography>
							<Typography variant="h5">{t('WE_GOT_YOU_COVERED')}</Typography>
							<Button href={PATHS_BY_ENV[env].loginLink} className={classes.btn}>
								{t('BEFTN_CTA_GET_FOR_FREE_BTN')}
							</Button>
						</Grid>
						<Grid item md={5} xs={12} alignItems="center">
							<Grid container justifyContent="center" marginTop={3}>
								<img src={transferSolutionImg} alt="transfer solution img" />
							</Grid>
						</Grid>
					</Grid>
				</CustomGrid>
			</Grid>
		</>
	);
};
