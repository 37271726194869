import Image1 from '@assets/svg/infos/whenNeeded.svg';
import Image2 from '@assets/svg/infos/confimDetails.svg';
import Image3 from '@assets/svg/infos/warning.svg';

export const BEFTN_INFO = [
	{
		img: Image1,
		title: 'WHEN_NEED_BEFTN_ROUTING',
		desc: 'WHEN_NEED_BEFTN_ROUTING_DESCR'
	},
	{
		img: Image2,
		title: 'CONFIRM_THE_DETAILS_INFO',
		desc: 'CONFIRM_THE_DETAILS_DESCR'
	},
	{
		img: Image3,
		title: 'WHAT_WILL_HAPPEN_INFO',
		desc: 'WHAT_WILL_HAPPEN_DESCR'
	}
];
