import { Trans, useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { BEFTN_INFO } from '@constant/beftnInfo';
import { RoutingExampleCard } from './RoutingExampleCard';

const BeftnInfoContent = () => {
	const { t } = useTranslation();

	return (
		<Grid container justifyContent="center" rowGap={4} margin="4rem 0">
			<Grid container textAlign="center" justifyContent="center" rowGap={1}>
				<Typography variant="h3">{t('WHAT_IS_BEFTN')}</Typography>
				<Typography variant="h5" width="90%">
					{t('WHAT_IS_BEFTN_DESCR')}
				</Typography>
			</Grid>

			<Grid container>
				<RoutingExampleCard />
			</Grid>

			{BEFTN_INFO.map((res, index) => (
				<Grid
					width="100%"
					container
					justifyContent="space-between"
					direction={index === 1 ? 'row-reverse' : 'row'}
					key={index}
					alignItems="center"
					margin="1rem 0">
					<Grid item xs={12} md={6}>
						<Grid container justifyContent="center">
							<img src={res.img} alt="pic" />
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack direction="column" rowGap={2}>
							<Typography variant="h3">{t(`${res.title}`)}</Typography>
							<Typography>
								<Trans i18nKey={res.desc} components={[<br />]} />
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			))}

			<Grid container justifyContent="center" textAlign="center" width={936} marginTop={5}>
				<Typography variant="h3">{t('JOIN_OUR_COMMUNITY')}</Typography>
			</Grid>
		</Grid>
	);
};

export default BeftnInfoContent;
