import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	subtitle: {
		color: '#9B9A97'
	},
	content: {
		color: '#333333'
	},
	container: {
		'& .MuiGrid-root': {
			padding: 0
		},
		width: '100%',
		padding: 0
	}
}));

const DetailsGridColumns = (props) => {
	const { data, isLoading } = props;
	const { classes } = useStyles();

	return (
		data &&
		data.map((item, index) => {
			const { subTitle, content, subtitleVariant, subtitleClassName, contentVariant, contentClassName } = item;

			return (
				<Grid item key={`${subTitle}-${index}`} className={classes.container}>
					{isLoading ? (
						<Skeleton />
					) : (
						<>
							<Grid container>
								<Grid item sm={3} xs={6}>
									<Typography variant={subtitleVariant || 'body2'} className={subtitleClassName || classes.subtitle}>
										{subTitle}
									</Typography>
								</Grid>
								<Grid item sm={9} xs={6}>
									<Typography component="div" variant={contentVariant || 'body1'} className={contentClassName || classes.content}>
										{content}
									</Typography>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			);
		})
	);
};

export default DetailsGridColumns;
