import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Button, Card, Grid, Typography } from '@mui/material';
import { useStateSafe } from '@effects/useStateSafe';
import { useRef } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import BasicTextInput from '@components/shared/forms/BasicTextInput';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DetailsGridColumns from '@components/shared/DetailsGridColumns';
import { useResult, useSetCurrentStep } from '@context/BeftnContext';

const useStyles = makeStyles()((theme) => ({
	formCard: {
		padding: 24,
		textAlign: 'left'
	},
	title: {
		fontSize: 24,
		padding: '0px 8px'
	},
	titleContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr auto'
	},
	detailsGrid: {
		padding: 24
	},
	fontCircle: {
		width: 36,
		height: 36,
		color: '#83DA85',
		marginRight: 15
	}
}));

const getDisplayDetails = (data, t) => {
	const { routingNo, swiftCode, bankName, branchName, distName }: any = data || {};

	return [
		...(routingNo
			? [
					{
						subTitle: t('ROUTING_NUMBER'),
						content: routingNo,
						contentVariant: 'h6'
					}
			  ]
			: []),
		...(bankName
			? [
					{
						subTitle: t('BANK'),
						content: bankName,
						contentVariant: 'h6'
					}
			  ]
			: []),

		...(distName
			? [
					{
						subTitle: t('DISTRICT'),
						content: distName,
						contentVariant: 'h6'
					}
			  ]
			: []),

		...(branchName
			? [
					{
						subTitle: t('BRANCH'),
						content: branchName,
						contentVariant: 'h6'
					}
			  ]
			: []),
		...(swiftCode
			? [
					{
						subTitle: t('SWIFT'),
						content: swiftCode,
						contentVariant: 'h6'
					}
			  ]
			: [])
	];
};

const RouteNumberResultBox = (props: any) => {
	const { result }: any = props || {};
	const { classes } = useStyles();
	const { t } = useTranslation();
	const [copySuccess, setCopySuccess] = useStateSafe('');
	const textInputRef = useRef(null);

	const copyToClipboard = (text) => {
		textInputRef?.current && textInputRef.current.select();
		setCopySuccess('Copied!');
		return document.execCommand('copy', true, text);
	};

	return (
		<>
			<Grid item xs={12}>
				<Grid container spacing={2} direction="row" justifyContent="center">
					<Grid item xs={12} md>
						<BasicTextInput type="text" ref={textInputRef} readOnly value={result?.routingNo} showHelperText helperText={copySuccess} />
					</Grid>
					<Grid item xs={12} md={2}>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							disableElevation
							onClick={copyToClipboard}
							style={{ borderRadius: 8, padding: '16px 24px' }}>
							{t('BEFTN_CHECKER_RESULT_COPY')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid className={classes.detailsGrid} container spacing={2} direction="column" alignItems="flex-start">
					<DetailsGridColumns data={getDisplayDetails(result, t)} />
				</Grid>
			</Grid>
		</>
	);
};

const BeftnCheckResultForm = (props: any) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const result = useResult();
	const setCurrentStep = useSetCurrentStep();

	return (
		<Card elevation={3} sx={{ padding: 3 }}>
			<Grid container spacing={2} direction="row" justifyContent="flex-start">
				<Grid item>
					<Button
						color="secondary"
						startIcon={<ArrowBackIosIcon />}
						onClick={() => {
							setCurrentStep(1);
						}}>
						{t('BEFTN_CHECKER_RESULT_BACK')}
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2} direction="column" alignItems="flex-start" justifyContent="center">
						<Grid item>
							<Grid container alignItems="center" className={classes.titleContainer}>
								<FiCheckCircle className={classes.fontCircle} />
								<Typography variant="h3" className={classes.title}>
									{t('BEFTN_CHECKER_RESULT_TITLE')}
								</Typography>
							</Grid>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('BEFTN_CHECKER_RESULT_SUBTITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">
								{t('BEFTN_CHECKER_RESULT_COUNT', {
									num: result?.length || 0
								})}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={3}>
						{result && result.length > 0 && result.map((routeResult, index) => <RouteNumberResultBox key={index} result={routeResult} />)}
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

export default BeftnCheckResultForm;
