import autoBind from 'auto-bind';
import { AxiosInstance } from 'axios';
import { API } from '../baseUrl';
import create from '../common.http';

export default class BEFTNService {
	private beftnApi: AxiosInstance;

	constructor() {
		this.beftnApi = create(API.BEFTN);
		autoBind(this);
	}

	public async postBEFTNFinder(req: any, token: string): Promise<any> {
		const response = await this.beftnApi.post(`web/bdt/finder`, req, { headers: { 'x-captcha-token': token } });
		return response;
	}

	public async getBEFTNByCode(beftnCode: string, token: string): Promise<any> {
		const response = await this.beftnApi.get(`web/bdt/${beftnCode}`, { headers: { 'x-captcha-token': token } });
		return response;
	}
}
