import Bugsnag from '@bugsnag/js';
import { OBFUSCATE_BEARER_TOKEN } from '@constant/bugsnag';
import { isEmptyObject } from '@helpers/Object';
import { isEmpty } from '@helpers/String';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API, getBaseUrl } from './baseUrl';

export default function create(apiName?: API, baseUrl?: string, headers?: any, retry = false, withExtraHeader = true): AxiosInstance {
	const axiosRequestConfig: AxiosRequestConfig = {};

	if (apiName) {
		axiosRequestConfig.baseURL = getBaseUrl(apiName);
	}

	if (baseUrl) {
		axiosRequestConfig.baseURL = baseUrl;
	}

	if (headers) {
		// if (headers.Authorization) {
		// 	const token = headers.Authorization.split('Bearer ')[1];
		// 	if (token) {
		// 		const tokenObj = parseJwt(token);
		// 		if (tokenObj !== null) {
		// 			if (isTokenExpired(tokenObj)) {
		// 				clearSession();
		// 			}
		// 		}
		// 	} else {
		// 		throw new axios.Cancel('Token is not available.');
		// 	}
		// }
		axiosRequestConfig.headers = headers;
	}

	const api: AxiosInstance = axios.create(axiosRequestConfig);

	if (withExtraHeader) {
		api.defaults.headers.common['x-application'] = 'web-beftn';
	}

	api.defaults.headers.common['Content-Type'] = 'application/json';
	api.defaults.headers.common.Accept = 'application/json';
	api.defaults.headers.get['Content-Type'] = 'application/json';
	api.defaults.headers.post['Content-Type'] = 'application/json';
	api.defaults.headers.put['Content-Type'] = 'application/json';
	api.defaults.headers.delete['Content-Type'] = 'application/json';

	api.interceptors.request.use(
		(request) => {
			// Do something before request is sent
			return request;
		},
		(error) => {
			// Do something with request error
			return Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		(response: any) => {
			return response;
		},
		(error: any) => {
			// if (error?.response?.status === 401) {
			// 	RefreshTokenProvider.getInstance().stop();
			// 	clearSession();
			// 	redirectToLoginPage();
			// }

			if (error.response) {
				const { response: errorResponse } = error || {};
				const { config: errorResponseConfig } = errorResponse || {};
				const { headers: errorConfigHeaders } = errorResponseConfig || {};

				errorResponseConfig.headers = {
					...errorConfigHeaders,
					Authorization: OBFUSCATE_BEARER_TOKEN
				};

				errorResponse.config = errorResponseConfig;

				Bugsnag.notify(new Error(JSON.stringify(errorResponse)));
			} else if (error.request) {
				const { request: errorRequest } = error || {};

				if (errorRequest.headers && !isEmpty(errorRequest.headers.Authorization)) {
					errorRequest.headers.Authorization = OBFUSCATE_BEARER_TOKEN;
				}

				Bugsnag.notify(new Error(JSON.stringify(errorRequest)));
			} else {
				!isEmptyObject(error.message) && Bugsnag.notify(new Error(JSON.stringify(error)));
			}

			return Promise.reject(error);
		}
	);

	return api;
}
