import { Button, Card, Grid, Typography } from '@mui/material';
import BeftnCheckResultForm from './bdtForms/RoutingResultForm';
import { useTranslation } from 'react-i18next';
import img from '@assets/images/ctaGetNewFree.png';
import { makeStyles } from 'tss-react/mui';
import { PATHS_BY_ENV } from '@constant/paths';
import { env } from '@helpers/EnvHelper';

const useStyles = makeStyles()((theme) => ({
	wrapGrid: {
		'& .MuiGrid-item': {
			paddingLeft: '16px'
		}
	},
	btn: {
		width: 286,
		color: 'white',
		marginTop: 16,
		'&.MuiButton-root': {
			padding: '16px 54px',
			color: 'white',
			background: 'radial-gradient(100% 730.99% at 0% 24.86%, #4568DC 0.49%, #B06AB3 92.48%)'
		}
	}
}));

const BdtResult = () => {
	const { t } = useTranslation();
	const { classes } = useStyles();

	return (
		<Grid container className={classes.wrapGrid} spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
			<Grid item xs={12} md={8}>
				<BeftnCheckResultForm />
			</Grid>
			<Grid item xs={12} md={4}>
				<Card sx={{ padding: 2 }}>
					<Grid container justifyContent="center" textAlign="center" rowGap={1}>
						<Grid item>
							<img src={img} alt="img" style={{ minHeight: 214, width: '80%' }} />
						</Grid>
						<Grid item>
							<Typography variant="h4">{t('BEFTN_CTA_GET_FOR_FREE_TITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('BEFTN_CTA_GET_FOR_FREE_SUBTITLE')}</Typography>
						</Grid>
						<Grid>
							<Button href={PATHS_BY_ENV[env].loginLink} className={classes.btn}>
								{t('BEFTN_CTA_GET_FOR_FREE_BTN')}
							</Button>
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</Grid>
	);
};

export default BdtResult;
