import { Button, Card, Grid, Typography } from '@mui/material';
import BasicTextInput from '../shared/forms/BasicTextInput';
import { useTranslation } from 'react-i18next';
import { useStateSafe } from '@effects/useStateSafe';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useErrorMsg, useRoutingNumber, useSetCurrentStep, useSetResult, useSetRoutingNumber } from '@context/BeftnContext';
import BEFTNService from '@network/api/beftn';
import { isEmpty } from '@helpers/String';

const useStyles = makeStyles()((theme) => ({
	formCard: {
		padding: 24,
		width: '45%',
		[theme.breakpoints.down('sm')]: {
			width: '90%'
		}
	},
	formGridContainer: {
		textAlign: 'left'
	},
	btn: {
		padding: '16px 24px'
	}
}));

const RoutingRetryForm = (props: any) => {
	const setCurrentStep = useSetCurrentStep();
	const routingNumber = useRoutingNumber();
	const errorMsg = useErrorMsg();
	const { classes } = useStyles();
	const { t } = useTranslation();
	const [newErrorMsg, setNewErrorMsg] = useStateSafe(errorMsg || '');
	const setResult = useSetResult();
	const setRoutingNumber = useSetRoutingNumber();
	const beftnService = new BEFTNService();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const validationSchema = yup.object({
		routingNumber: yup
			.string()
			.min(9, (obj) => t('BEFTN__INPUT_LENGTH', { len: obj.value.length }))
			.max(9, (obj) => t('BEFTN__INPUT_LENGTH', { len: obj.value.length }))
			.required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			routingNumber
		},
		enableReinitialize: true,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const { routingNumber: retryRoutingNumber }: any = values || {};
				const newToken = executeRecaptcha && (await executeRecaptcha('RoutingRetryForm'));
				const result = await beftnService.getBEFTNByCode(retryRoutingNumber, newToken);
				const { data: resultData } = result;

				if (!isEmpty(resultData)) {
					setRoutingNumber(retryRoutingNumber);
					setResult && setResult([resultData]);
					setCurrentStep && setCurrentStep(2);
				}
			} catch (err: any) {
				const { message, statusCode, status }: any = err?.response?.data || {};

				setNewErrorMsg && setNewErrorMsg(message);
				setCurrentStep && setCurrentStep(3);
				console.error('Error RoutingRetryForm onSubmit', err);
			}
		}
	});

	return (
		<Grid container justifyContent="center">
			<Card elevation={3} className={classes.formCard}>
				<Grid container spacing={2} className={classes.formGridContainer} direction="column" justifyContent="center">
					<Grid item xs={12}>
						<Button
							color="secondary"
							startIcon={<ArrowBackIosIcon />}
							onClick={() => {
								setCurrentStep(1);
							}}>
							{t('BEFTN_CHECKER_RESULT_BACK')}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} direction="column" alignItems="flex-start" justifyContent="center">
							<Grid item>
								<Typography variant="h3">{t('BEFTN_RETRY_FORM_TITLE')}</Typography>
							</Grid>
							<Grid item>
								<Typography variant="subtitle1">{t('BEFTN_RETRY_FORM_SUBTITLE')}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<BasicTextInput
							label={t('CHECK_BEFTN_NAME')}
							type="text"
							name="routingNumber"
							value={formik.values.routingNumber}
							placeholder={t('CHECK_BEFTN_NAME')}
							onChange={(e) => {
								setNewErrorMsg('');
								formik.setFieldValue('routingNumber', e.target.value);
							}}
							onBlur={formik.handleBlur}
							error={Boolean(newErrorMsg) || (formik.touched.routingNumber && Boolean(formik.errors.routingNumber))}
							helperText={newErrorMsg || formik.errors.routingNumber}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							className={classes.btn}
							variant="contained"
							color="primary"
							fullWidth
							disabled={formik.isSubmitting}
							onClick={() => formik.handleSubmit()}>
							<Typography variant="button">{formik.isSubmitting ? t('SUBMITTING') : t('CHECK_ROUTING_NUMBER')}</Typography>
						</Button>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
};

export default RoutingRetryForm;
