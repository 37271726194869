import { ContentMain } from '@components/ContentMain';
import Footer from '@components/shared/footer/Footer';
import Header from '@components/shared/header/Header';
import { useVisitedRegion } from '@effects/useVisitedRegion';
import MainLayout from '@layouts/MainLayout';

const LandingPage = () => {
	const { countryNameToDisplay } = useVisitedRegion();

	return <MainLayout header={<Header />} content={<ContentMain />} footer={<Footer countryNameToDisplay={countryNameToDisplay} />} />;
};

export default LandingPage;
