import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Card, Grid, Stack, Typography } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
	card: {
		width: '100%',
		marginTop: 24,
		padding: 24
	}
}));

export const RoutingExampleCard = () => {
	const { classes } = useStyles();
	const { t } = useTranslation();

	return (
		<Grid container>
			<Card className={classes.card}>
				<Grid>
					<Typography variant="h5" textAlign="center" marginBottom={2}>
						{t('EXAMPLE_OF_BEFTN')}
					</Typography>
					<Grid container justifyContent="center" spacing={3}>
						<Grid item>
							<Stack direction="column" alignItems="center">
								<Typography variant="h3">123</Typography>
								<Typography variant="body2">{t('BANK_CODE')}</Typography>
							</Stack>
						</Grid>
						<Grid item>
							<Stack direction="column" alignItems="center">
								<Typography variant="h3">00</Typography>
								<Typography variant="body2">{t('DISTRICT_CODE')}</Typography>
							</Stack>
						</Grid>
						<Grid item>
							<Stack direction="column" alignItems="center">
								<Typography variant="h3">123</Typography>
								<Typography variant="body2">{t('BRANCH_CODE')}</Typography>
							</Stack>
						</Grid>
						<Grid item>
							<Stack direction="column" alignItems="center">
								<Typography variant="h3">0</Typography>
								<Typography variant="body2">{t('CHECK_DIGIT')}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
};
