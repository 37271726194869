export enum API {
	BEFTN = 'BEFTN'
}

export function getBaseUrl(code: API): string {
	switch (code) {
		case API.BEFTN:
			return process.env.REACT_APP_URL_BEFTN;
		default:
			return code;
	}
}
