import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locale/en/index';

const resources = {
	en: {
		translation: {
			...enTranslations
		}
	}
};

const options = {
	order: ['path', 'navigator'],
	lookupFromPathIndex: 0
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: options,
		resources,
		fallbackLng: 'en',
		supportedLngs: ['en'],
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
