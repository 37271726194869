import { Grid } from '@mui/material';
import RoutingRetryForm from './bdtForms/RoutingRetryForm';

const BdtRetry = () => {
	return (
		<Grid container>
			<RoutingRetryForm />
		</Grid>
	);
};

export default BdtRetry;
