import { useFormik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import { Button, Card, Grid, Typography } from '@mui/material';
import { useSetCurrentStep, useSetErrorMsg, useSetResult, useSetRoutingNumber } from '@context/BeftnContext';
import { handleKeyDown } from '@helpers/OnKeyDown';
import BasicTextInput from '../shared/forms/BasicTextInput';
import BEFTNService from '@network/api/beftn';
import { isEmpty } from '@helpers/String';

const useStyles = makeStyles()((theme) => ({
	formCard: {
		padding: 24
	},
	btn: {
		padding: '16px 24px'
	}
}));

const RouteCheckerForm = (props: any) => {
	const setCurrentStep = useSetCurrentStep();
	const setErrorMsg = useSetErrorMsg();
	const { classes } = useStyles();
	const { t } = useTranslation();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const beftnService = new BEFTNService();
	const setResult = useSetResult();
	const setRoutingNmber = useSetRoutingNumber();

	const validationSchema = yup.object({
		routingNumber: yup
			.string()
			.min(9, (obj) => t('BEFTN__INPUT_LENGTH', { len: obj.value.length }))
			.max(9, (obj) => t('BEFTN__INPUT_LENGTH', { len: obj.value.length }))
			.required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			routingNumber: ''
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const { routingNumber }: any = values || {};
				setRoutingNmber(routingNumber);
				const newToken = executeRecaptcha && (await executeRecaptcha('RouteCheckerForm'));
				const result = await beftnService.getBEFTNByCode(routingNumber, newToken);
				const { data: resultData } = result;
				if (!isEmpty(resultData)) {
					setResult && setResult([resultData]);
					setCurrentStep && setCurrentStep(2);
				}
			} catch (err: any) {
				const { message }: any = err?.response?.data || {};
				setErrorMsg && setErrorMsg(message);
				setCurrentStep && setCurrentStep(3);
				console.error('Error RoutingNumCheckerForm onSubmit', err);
			}
		}
	});

	return (
		<Card elevation={3} className={classes.formCard}>
			<Grid container spacing={2} direction="column" justifyContent="center">
				<Grid item xs={12}>
					<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
						<Grid item>
							<Typography variant="h3">{t('CHECK_BEFTN_FORM_TITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('CHECK_BEFTN_FORM_SUBTITLE')}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<BasicTextInput
						label="CHECK_ROUTING_NAME"
						type="text"
						value={formik.values.routingNumber}
						name="routingNumber"
						placeholder="e.g. 20150130"
						onChange={(e) => {
							formik.setFieldValue('routingNumber', e.target.value);
						}}
						onKeyDown={(e) => {
							handleKeyDown(e, 'Enter', formik.handleSubmit);
						}}
						onBlur={formik.handleBlur}
						error={formik.touched.routingNumber && Boolean(formik.errors.routingNumber)}
						helperText={formik.errors.routingNumber}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						className={classes.btn}
						variant="contained"
						color="primary"
						fullWidth
						disabled={formik.isSubmitting}
						onClick={() => formik.handleSubmit()}>
						<Typography variant="button">{formik.isSubmitting ? t('SUBMITTING') : t('CHECK_ROUTING_NUMBER')}</Typography>
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default RouteCheckerForm;
