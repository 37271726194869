const typography = {
	fontFamily: 'Poppins',
	h1: {
		fontWeight: 400,
		fontSize: '3rem',
		lineHeight: 1.25,
		letterSpacing: '0em'
	},
	h2: {
		fontWeight: 700,
		fontSize: '2.5rem',
		lineHeight: 1.25,
		letterSpacing: '0em'
	},
	h3: {
		fontWeight: 700,
		fontSize: '1.5rem',
		lineHeight: 1.5,
		letterSpacing: '0em'
	},
	h4: {
		fontWeight: 700,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0em'
	},
	h5: {
		fontWeight: 400,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0em'
	},
	h6: {
		fontWeight: 600,
		fontSize: '0.875rem',
		lineHeight: 1.5,
		letterSpacing: '-0.02em'
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: '0.875rem',
		lineHeight: 1.5,
		letterSpacing: '0.0015em'
	},
	subtitle2: {
		fontWeight: 600,
		fontSize: '0.625rem',
		lineHeight: 1.5,
		letterSpacing: '0em'
	},
	body1: {
		fontWeight: 400,
		fontSize: '0.875rem',
		lineHeight: 1.5,
		letterSpacing: '0em'
	},
	body2: {
		fontWeight: 400,
		fontSize: '0.75rem',
		lineHeight: 1.5,
		letterSpacing: '-0.02em'
	},
	caption: {
		fontWeight: 400,
		fontSize: '0.625rem',
		lineHeight: 1.5,
		letterSpacing: '0.004em'
	},
	button: {
		fontWeight: 600,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0em',
		textTransform: 'none'
	},
	overline: {
		fontWeight: 400,
		fontSize: '0.625rem',
		lineHeight: 1.5,
		letterSpacing: '0.015em'
	}
};

export default typography;
