import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import gradientBackground from '@assets/images/gradientBackground.png';

const useStyles = makeStyles()((theme) => ({
	contentContainer: {
		backgroundImage: `url(${gradientBackground})`,
		backgroundRepeat: 'repeat-x repeat-y',
		width: '100%',
		backgroundSize: 'cover',
		[theme.breakpoints.up('md')]: {
			marginTop: 56
		}
	}
}));

const MainLayout = (props) => {
	const { header, content, footer } = props;
	const { classes } = useStyles();

	return (
		<Grid container direction="column">
			<Grid item xs={12}>
				{header}
			</Grid>
			<Grid item xs={12} className={classes.contentContainer}>
				{content}
			</Grid>
			<Grid item xs={12}>
				{footer}
			</Grid>
		</Grid>
	);
};

export default MainLayout;
