import { useCurrentStep } from '@context/BeftnContext';
import { withInfoSection } from '../hoc/withContentLayout';
import BdtFind from './BdtFind';
import BdtResult from './BdtResult';
import BdtRetry from './BdtRetry';

const FormSection = (props) => {
	const currentStep = useCurrentStep();

	switch (currentStep) {
		case 1:
			return <BdtFind />;
		case 2:
			return <BdtResult />;
		case 3:
			return <BdtRetry />;
		default:
			return null;
	}
};

export const ContentMain = withInfoSection(FormSection);
