import { MMT_REGION_NAME } from '@constant/region';
import { useStateSafe } from '@effects/useStateSafe';
import { isEmpty } from '@helpers/String';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ListItemIcon, Menu, MenuItem, MenuProps, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { ClickAwayListener } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={2}
		disableScrollLock={false}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 221,
		color: theme.palette.mode === 'light' ? theme.surfaceVariants?.primary?.onSurface : theme.palette.grey[300],
		boxShadow: `${theme.palette.background.paper} 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`,
		'& .MuiMenu-list': {
			padding: '8px 20px'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
			}
		}
	}
}));

const useStyles = makeStyles()((theme) => ({
	button: {
		height: 37,
		backgroundColor: theme.palette.background.paper,
		color: theme.surfaceVariants?.secondary?.onSurface,
		borderRadius: 8
	}
}));

const RegionPickerMenu = (props) => {
	const { data, value } = props;
	const { classes } = useStyles();

	const [anchorEl, setAnchorEl] = useStateSafe<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onItemClick = (siteUrl) => {
		handleClose();
		window.open(siteUrl, '_blank');
	};

	const renderOptions = () => {
		return (
			data &&
			data.map((item, index) => {
				const { countryCode, name: itemLabel, imgUrl: imgIcon, isActive, transferLink } = item;

				if (!isActive || isEmpty(transferLink)) return null;

				return (
					<MenuItem
						key={index}
						value={MMT_REGION_NAME[countryCode]}
						sx={{
							justifyContent: 'left',
							alignItem: 'center',
							padding: '12px'
						}}
						disableRipple
						onClick={() => {
							onItemClick(transferLink);
						}}>
						{imgIcon && (
							<ListItemIcon>
								<img src={imgIcon} alt={`${itemLabel}-${index}`} width={24} height={24} />
							</ListItemIcon>
						)}
						{itemLabel}
					</MenuItem>
				);
			})
		);
	};

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div>
				<Button
					id="region-picker-button"
					aria-controls={open ? 'region-picker-dropdown-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					variant="contained"
					disableElevation
					className={classes.button}
					onClick={handleClick}
					color="inherit"
					endIcon={<KeyboardArrowDownIcon />}>
					<Typography variant="h6">{value}</Typography>
				</Button>
				<StyledMenu
					id="region-picker-dropdown-menu"
					MenuListProps={{
						'aria-labelledby': 'region-picker-button'
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}>
					{renderOptions()}
				</StyledMenu>
			</div>
		</ClickAwayListener>
	);
};

export default RegionPickerMenu;
