/* eslint-disable max-len */
const landing = {
	TOOLS: 'Tools',
	BDT_ROUTING_NUM: 'BDT Routing Number',
	IFSC: 'IFSC',
	BDT: 'BDT',
	BANGLADESHI_TAKA: 'Bangladeshi Taka',
	ROUTING_NUMBER: 'Routing Number',
	ROUTING_NUMBER_DESC: `Everything you need to get the right Bangladesh Bank BEFTN Routing Number for your transfer. <0/> Rest assured, we don't keep or view any of the data you enter here`,
	WHAT_IS_BEFTN: 'What is a BEFTN Routing Number?',
	WHAT_IS_BEFTN_DESCR:
		'BEFTN is short for Bangladesh Electronic Funds Transfer Network and represents the 9 digit code associated to the bank branch of your bank account. This 9 digit code helps identify the individual bank branches in Bangladesh.The combination of 3 digits of bank. Every bank branch will have a unique code and no two branches (even of the same bank) will ever be the same.',
	WHEN_NEED_BEFTN_ROUTING: 'When will you need a BEFTN routing number?',
	WHEN_NEED_BEFTN_ROUTING_DESCR:
		'An BEFTN Routing Number is required when you are making a BDT transfer to Bangladesh, particularly via transfer options like BEFTN. This code is mandatory for fund transfers from one bank account to another.',
	CONFIRM_THE_DETAILS_INFO: 'Always confirm the details with your recipient',
	CONFIRM_THE_DETAILS_DESCR:
		'We can tell whether your BEFTN routing number is in the valid format, but we can’t guarantee that it exists, or that it represents the intended account. This tool should help you to confirm some of the details you already know, such as the country and bank. <0/><0/> When sending or receiving money, always check the routing number with your recipient or bank.',
	WHAT_WILL_HAPPEN_INFO: 'What will happen if I have used the wrong routing number?',
	WHAT_WILL_HAPPEN_DESCR: `If you think you’ve used the wrong BEFTN routing number to send money, contact us immediately. Depending on what extent has the order been processed, we will notify you accordingly on the available solutions. <0/><0/> MoneyMatch cannot reverse or recall successful payments. Once the funds have been credited to your recipient account, the order cannot be recalled or refunded.`,
	JOIN_OUR_COMMUNITY:
		'Join our community of more than 100,000 users who rely on MoneyMatch every day to send money home to their families, pay for goods and services abroad or expand their payment network around the world.',
	TRANSFER_SOLUTION: 'The transfer solution for everyone',
	WE_GOT_YOU_COVERED: 'We’ve got you covered!',
	BEFTN_CTA_GET_FOR_FREE_BTN: 'Get Started for free',
	EXAMPLE_OF_BEFTN: 'An example of a BEFTN Routing Number',
	BANK_CODE: 'Bank code',
	DISTRICT_CODE: 'District code',
	BRANCH_CODE: 'Branch code',
	CHECK_DIGIT: 'Check digit',
	CHECK_BEFTN_FORM_TITLE: 'Check a BEFTN Routing Number',
	CHECK_BEFTN_FORM_SUBTITLE: 'Type it in here and we’ll tell you if it’s the right format.',
	CHECK_ROUTING_NAME: 'Enter your Routing Number',
	CHECK_ROUTING_NUMBER: 'Check Routing Number',

	FIND_BEFTN_FORM_TITLE: 'Find BEFTN Routing Number',
	FIND_BEFTN_FROM_DESCR: 'Fill in the bank details, and we’ll find a Bangladesh bank BEFTN routing number for you.',
	SELECT_A_BANK: 'Select a bank',
	SELECT_DISTRICT: 'Select district',
	SELECT_BRANCH: 'Select branch',
	FIND_ROUTE_NUMBER_BTN: 'Find Routing Number',
	BEFTN_CHECKER_RESULT_BACK: 'Back',
	BEFTN_CHECKER_RESULT_TITLE: 'This routing number seems right',
	BEFTN_CHECKER_RESULT_SUBTITLE: `Remember to check it with your recipient or bank though. We can’t tell you if it’s real, or if it’s the exact routing number for a particular account.`,
	BEFTN_CHECKER_RESULT_COUNT: '{{num}} results found.',
	BEFTN_CTA_GET_FOR_FREE_TITLE: 'Got the right information? Now get a better deal for sending money.',
	BEFTN_CTA_GET_FOR_FREE_SUBTITLE: 'We offer bank-beating rates and no hidden fees so you can be confident in every transfer you make.',
	BEFTN_CHECKER_RESULT_COPY: 'Copy',
	BANK: 'Bank',
	DISTRICT: 'District',
	BRANCH: 'Branch',
	SWIFT: 'SWIFT',
	BEFTN__INPUT_LENGTH: 'The length of this routing number is incorrect ({{len}} characters). The correct length should be 9 characters.',
	BEFTN_RETRY_FORM_TITLE: `These details don't look right`,
	BEFTN_RETRY_FORM_SUBTITLE: `Type it in here and we'll tell you if it's the right format.`,
	CHECK_BEFTN_NAME: 'Enter your Routing Number',
	SUBMITTING: 'Submitting..',
	REQUIRED: 'Required'
};

export default landing;
