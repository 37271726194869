import LandingPage from '@views/LandingPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<LandingPage />} />
			</Routes>
		</Router>
	);
};

export default AppRoutes;
