import { Grid } from '@mui/material';
import RouteCheckerForm from './bdtForms/RouteCheckerForm';
import BankInfoCheckerForm from './bdtForms/BankInfoCheckerForm';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	wrapGrid: {
		'& .MuiGrid-item': {
			paddingLeft: '16px'
		}
	}
}));

const BdtFind = (props) => {
	const { classes } = useStyles();

	return (
		<Grid className={classes.wrapGrid} container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
			<Grid item xs={12} md={6}>
				<RouteCheckerForm />
			</Grid>
			<Grid item xs={12} md={6}>
				<BankInfoCheckerForm />
			</Grid>
		</Grid>
	);
};

export default BdtFind;
