import { REGION } from '@constant/locale';

export const getZendeskEnv = () => {
	const region = localStorage.getItem(REGION) || 'MY';

	switch (region) {
		case 'BN':
			return process.env.REACT_APP_ZENDESK_KEY_BN;
		case 'AU':
			return process.env.REACT_APP_ZENDESK_KEY_AU;
		default:
			return process.env.REACT_APP_ZENDESK_KEY || '';
	}
};

export const env: any = process.env.REACT_APP_ENV ?? 'production';
