import BeftnContextProvider from '@context/BeftnContext';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './App.css';
import AppRoutes from './routes/AppRoutes';
import theme from './theme';
import { clarity } from 'clarity-js';

function App() {
	useEffect(() => {
		window.process = {
			...window?.process
		};

		initClarityTracker();

		return () => {
			clarity.stop();
		};
	}, []);

	const initClarityTracker = () => {
		clarity.consent();
		clarity.start({
			projectId: process.env.REACT_APP_CLARITY_KEY || '',
			upload: 'https://m.clarity.ms/collect',
			track: true,
			content: true
		});
	};

	return (
		<ThemeProvider theme={theme}>
			<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHAKEY} scriptProps={{ async: true }}>
				<BeftnContextProvider>
					<AppRoutes />
				</BeftnContextProvider>
			</GoogleReCaptchaProvider>
		</ThemeProvider>
	);
}

export default App;
