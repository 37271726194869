/* eslint-disable max-len */
const header = {
	LOGIN: 'Login',
	SIGNUP: 'Sign Up',
	PRODUCTS: 'Products',
	COMPANY: 'Company',
	PERSONAL: 'Personal',
	BUSINESS: 'Business',
	RESOURCES: 'Resources',
	DEVELOPERS: 'Developers',
	MM_ALL_RIGHTS_RESERVE: 'MoneyMatch Sdn.Bhd.2022.All rights reserved.',
	MM_ALL_RIGHTS_RESERVED2: 'Sitemap • Legal • Privacy Policy',
	COPYRIGHT_INFO:
		'MoneyMatch Sdn.Bhd. is regulated by Central Bank (licensee providing digital services) under MY Licence Serial Number 00249 in Malaysia. <0/>Perniagaan Perkhidmatan Wang Berlesen | Pengirim Wang',
	HEADER_TAB_TRANSFER: 'Transfer',
	HEADER_TAB_INSURANCE: 'Insurance',
	HEADER_TAB_SUPPORT: 'Support Center',
	HEADER_TAB_CONTACT: 'Contact Us',
	HEADER_TAB_PRODUCTS: 'Products',
	HEADER_TAB_PROMOTIONS: 'Promotions',
	HEADER_TAB_SUPPORT2: 'Support',
	HEADER_TAB_COMPANY: 'Company',
	TRANSFER: 'Transfer',
	TRANSFER_BUSINESS: 'Transfer for Business',
	TRANSFER_DESC: 'Say farewell to expensive fees when you send money abroad to friends and family',
	TRANSFER_BUSINESS_DESC: 'Outsmart your competitors. Best exchange rates and flat fees when making overseas business payments',
	SME_INSURANCE_DESC: 'Get Conventional or Takaful products from best insurers for your business',
	ABOUT: 'About MoneyMatch',
	ABOUT_DESC: 'A snapshot of who we are',
	BLOG: 'Blog',
	BLOG_DESC: 'Our insights and more...',
	CAREERS: 'Careers',
	CAREERS_DESC: 'Join us on our mission to redefine finance!',
	SME_INSURANCE: 'SME Insurance'
};

export default header;
