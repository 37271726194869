import { useStateSafe } from '@effects/useStateSafe';
import { createContext, useContextSelector } from 'use-context-selector';

export const BeftnContext = createContext(null);

const BeftnContextProvider = ({ children }) => {
	const [currentStep, setCurrentStep] = useStateSafe<number>(1);
	const [routingNumber, setRoutingNumber] = useStateSafe<string>('');
	const [result, setResult] = useStateSafe({});
	const [errorMsg, setErrorMsg] = useStateSafe<string>('');

	const contextProps = {
		currentStep,
		setCurrentStep,
		routingNumber,
		setRoutingNumber,
		result,
		setResult,
		errorMsg,
		setErrorMsg
	};

	return <BeftnContext.Provider value={contextProps}>{children}</BeftnContext.Provider>;
};

export const useCurrentStep = () => useContextSelector(BeftnContext, (s) => s.currentStep);
export const useSetCurrentStep = () => useContextSelector(BeftnContext, (s) => s.setCurrentStep);
export const useRoutingNumber = () => useContextSelector(BeftnContext, (s) => s.routingNumber);
export const useSetRoutingNumber = () => useContextSelector(BeftnContext, (s) => s.setRoutingNumber);
export const useResult = () => useContextSelector(BeftnContext, (s) => s.result);
export const useSetResult = () => useContextSelector(BeftnContext, (s) => s.setResult);
export const useErrorMsg = () => useContextSelector(BeftnContext, (s) => s.errorMsg);
export const useSetErrorMsg = () => useContextSelector(BeftnContext, (s) => s.setErrorMsg);

export default BeftnContextProvider;
