/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Button, Card, Grid, LinearProgress, Typography } from '@mui/material';
import MultiSelectDropdown from '../shared/forms/MultiSelectDropdown';
import { useStateSafe } from '@effects/useStateSafe';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import { isEmpty } from '@helpers/String';
import { isEmptyArray } from '@helpers/Array';
import { useSetCurrentStep, useSetResult } from '@context/BeftnContext';
import { handleKeyDown } from '@helpers/OnKeyDown';
import BEFTNService from '@network/api/beftn';
import { BankOptions, BranchOptions, DistrictOptions } from '@interfaces/BEFTN';

const useStyles = makeStyles()((theme) => ({
	formCard: {
		padding: 24
	},
	btn: {
		padding: '16px 24px'
	}
}));

const BankInfoCheckerForm = (props: any) => {
	const setCurrentStep = useSetCurrentStep();
	const setResult = useSetResult();
	const { classes } = useStyles();
	const { t } = useTranslation();
	const [selectedBank, setSelectedBank] = useStateSafe(null);
	const [selectedDistrict, setSelectedDistrict] = useStateSafe(null);
	const [selectedBranch, setSelectedBranch] = useStateSafe(null);
	const [bankList, setBankList] = useStateSafe<Array<BankOptions>>([]);
	const [districtList, setDistrictList] = useStateSafe<Array<DistrictOptions>>([]);
	const [branchList, setBranchList] = useStateSafe<Array<BranchOptions>>([]);
	const beftnService = new BEFTNService();
	const { executeRecaptcha } = useGoogleReCaptcha();

	useEffect(() => {
		const fetchBankList = async () => {
			try {
				const newToken = await executeRecaptcha('fetchBankList');
				const result = await beftnService.postBEFTNFinder({}, newToken);
				if (result?.status === 200) {
					const bankArr = result?.data?.map((x) => ({ label: x?.bankName, value: x?.bankCode }));
					setBankList(bankArr);
				}
			} catch (error) {
				console.error('fetchBankList error ', error);
			}
		};

		if (!executeRecaptcha) {
			console.error('Execute recaptcha not yet available');
			return;
		} else {
			fetchBankList();
		}
	}, [executeRecaptcha]);

	useEffect(() => {
		if (!isEmpty(selectedBank) && !isEmpty(selectedDistrict)) {
			fetchBranchList();
		} else if (!isEmpty(selectedBank)) {
			fetchDistrictList();
		}
	}, [selectedBank, selectedDistrict]);

	const validationSchema = yup.object({
		bank: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED')),
		district: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED')),
		branch: yup.string().required(t('REQUIRED')).nullable().typeError(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			bank: '',
			district: '',
			branch: ''
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async (values) => {
			try {
				const { bank, district, branch }: any = values || {};
				const newToken = executeRecaptcha && (await executeRecaptcha('bankInfoCheckerForm'));
				const result = await beftnService.postBEFTNFinder({ bankCode: bank, distCode: district, branchCode: branch }, newToken);
				const { data: resultData } = result;
				setResult && setResult(resultData);
				setCurrentStep && setCurrentStep(2);
				window.scrollTo(0, 0);
			} catch (e) {
				console.error('Error BankInfoCheckerForm onSubmit', e);
			}
		}
	});

	const fetchDistrictList = async () => {
		try {
			const newToken = executeRecaptcha && (await executeRecaptcha('fetchDistrictList'));
			const result = await beftnService.postBEFTNFinder({ bankCode: formik.values.bank }, newToken);
			if (result?.status === 200) {
				const districtArr = result?.data?.map((x) => ({ label: x?.distName, value: x?.distCode }));
				setDistrictList(districtArr);
			}
		} catch (error) {
			console.error('Error RoutingBankCheckerForm fetchDistrictList', error);
		}
	};

	const fetchBranchList = async () => {
		try {
			const newToken = executeRecaptcha && (await executeRecaptcha('fetchBranchList'));
			const result = await beftnService.postBEFTNFinder(
				{
					bankCode: formik.values.bank,
					distCode: formik.values.district
				},
				newToken
			);
			if (result?.status === 200) {
				const branchArr = result?.data?.map((x) => ({ label: `${x?.branchName} - ${x?.branchCode}`, value: x?.branchCode }));
				setBranchList(branchArr);
			}
		} catch (error) {
			console.error('Error BankInfoCheckerForm fetchBranchList', error);
		}
	};

	const resetFields = (step: number) => {
		switch (step) {
			case 1: {
				formik.setFieldValue('district', '');
				formik.setFieldValue('branch', '');
				setSelectedDistrict(null);
				setSelectedBranch(null);
				formik.touched.district = false;
				formik.touched.branch = false;
				break;
			}
			case 2: {
				formik.setFieldValue('branch', '');
				setSelectedBranch(null);
				formik.touched.branch = false;
				break;
			}
		}
	};

	return (
		<Card elevation={3} className={classes.formCard}>
			<Grid container spacing={2} direction="column" justifyContent="center">
				<Grid item xs={12}>
					<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
						<Grid item>
							<Typography variant="h3">{t('FIND_BEFTN_FORM_TITLE')}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{t('FIND_BEFTN_FROM_DESCR')}</Typography>
						</Grid>
						{isEmptyArray(bankList) && <LinearProgress style={{ width: '95%' }} />}
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<MultiSelectDropdown
						options={bankList}
						value={selectedBank}
						placeholder={t('SELECT_A_BANK')}
						onChange={(event, values) => {
							if (!isEmpty(formik.values.bank)) {
								resetFields(1);
							}
							formik.setFieldValue('bank', values?.value);
							setSelectedBank(values);
						}}
						error={formik.touched.bank && Boolean(formik.errors.bank)}
						helperText={formik.errors.bank}
						disabled={isEmptyArray(bankList)}
					/>
				</Grid>

				<Grid item xs={12}>
					<MultiSelectDropdown
						options={districtList}
						value={selectedDistrict}
						placeholder={t('SELECT_DISTRICT')}
						onChange={(event, values) => {
							if (!isEmpty(formik.values.district)) {
								resetFields(2);
							}
							formik.setFieldValue('district', values?.value);
							setSelectedDistrict(values);
						}}
						error={formik.touched.district && Boolean(formik.errors.district) && !isEmptyArray(districtList) && !isEmpty(formik.values.bank)}
						helperText={formik.errors.district}
						disabled={isEmptyArray(districtList) || isEmpty(formik.values.bank)}
					/>
				</Grid>

				<Grid item xs={12}>
					<MultiSelectDropdown
						options={branchList}
						value={selectedBranch}
						placeholder={t('SELECT_BRANCH')}
						onChange={(event, values) => {
							formik.setFieldValue('branch', values?.value);
							setSelectedBranch(values);
						}}
						error={formik.touched.branch && Boolean(formik.errors.branch) && !isEmptyArray(branchList) && !isEmpty(formik.values.district)}
						helperText={formik.errors.branch}
						disabled={isEmptyArray(branchList) || isEmpty(formik.values.district)}
						onKeyDown={(e) => {
							handleKeyDown(e, 'Enter', formik.handleSubmit);
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<Button
						className={classes.btn}
						variant="contained"
						color="primary"
						disableRipple
						fullWidth
						disabled={formik.isSubmitting}
						onClick={() => formik.handleSubmit()}>
						{formik.isSubmitting ? t('SUBMITTING') : t('FIND_ROUTE_NUMBER_BTN')}
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default BankInfoCheckerForm;
